import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { enqueueSnackbar } from "./notifier";
import { serialize } from "object-to-formdata";

const initialState = {
  shortcuts: [],
  allShortcuts: [],
  statusAllShortcuts: "idle",
  status: "idle",
  deleteStatus: "idle",
  shortcutslength: 0,
  createshortcutstatus: "idle",
  updateShortcutStatus: "idle",
  createstatus: "idle",
  storeData: [],
  shortcut: "",
  error: null,
};

export const getAllshortcuts = createAsyncThunk(
  "conversation/getAllshortcuts",

  async (query) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${query.websiteID}/shortcuts/${query.query}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return query.query === "" ? data : { query: query.query, data };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createshortcut = createAsyncThunk(
  "conversation/createshortcut",
  async (shortcut, thunkAPI) => {
    let data;
    const vals = serialize(shortcut.shortcut);
    try {
      const response = await axios.post(
        `/websites/${shortcut.websiteID}/shortcuts`,
        vals
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          getAllshortcuts({ websiteID: shortcut.websiteID, query: "" })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateShortcut = createAsyncThunk(
  "conversation/updateShortcut",
  async (shortcut, thunkAPI) => {
    let data;

    try {
      const response = await axios.patch(
        `/websites/${shortcut.websiteID}/shortcuts/${shortcut.id}`,
        shortcut.shortcut
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          getAllshortcuts({ websiteID: shortcut.websiteID, query: "" })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteShortcut = createAsyncThunk(
  "conversation/deleteShortcut",
  async (query, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/${query.websiteID}/shortcuts/${query.id}`
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          getAllshortcuts({ websiteID: query.websiteID, query: "" })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err.message);
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    getShortcut: (state, action) => {
      state.shortcut = action.payload;
    },
  },
  extraReducers: {
    [getAllshortcuts.pending]: (state) => {
      state.status = "loading";
      state.statusAllShortcuts = "loading";
    },
    [getAllshortcuts.fulfilled]: (state, action) => {
      state.status = "succeeded";
      action.payload?.query
        ? (() => {
            state.status = "succeeded";
            state.shortcuts = action.payload.data;
            state.shortcutlength = state.shortcuts.length;
          })()
        : (() => {
            state.statusAllShortcuts = "succeeded";
            state.allShortcuts = action.payload;
          })();
    },
    [getAllshortcuts.rejected]: (state, action) => {
      action.payload?.query
        ? (state.status = "failed")
        : (state.statusAllShortcuts = "failed");
    },

    [createshortcut.pending]: (state) => {
      state.createshortcutstatus = "loading";
    },
    [createshortcut.fulfilled]: (state, action) => {
      state.createshortcutstatus = "succeeded";
      state.shortcuts.push(action.payload.data);
    },
    [createshortcut.rejected]: (state, action) => {
      state.createshortcutstatus = "failed";
      state.error = action.payload;
    },
    [deleteShortcut.pending]: (state) => {
      state.deleteStatus = "loading";
    },
    [deleteShortcut.fulfilled]: (state, action) => {
      state.deleteStatus = "succeeded";
    },
    [deleteShortcut.rejected]: (state, action) => {
      state.deleteStatus = "failed";
      state.error = action.payload;
    },
    [updateShortcut.pending]: (state) => {
      state.updateShortcutStatus = "loading";
    },
    [updateShortcut.fulfilled]: (state, action) => {
      state.updateShortcutStatus = "succeeded";
    },
    [updateShortcut.rejected]: (state, action) => {
      state.updateShortcutStatus = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const { getShortcut } = slice.actions;
export default slice;
